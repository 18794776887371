import React, {useEffect, useState} from "react";
import {PersonTemplateProps} from "./types";
import {lookupPerson, getPerson, Person} from "../../core/system/persons";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {useLocation, useHistory, Link as RouterLink} from "react-router-dom";
import {useAuthentication} from "../../core/providers/AuthenticationProvider";
import {CircularProgress} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";

const PersonTemplate: React.FC<PersonTemplateProps> = ({personId}) => {
  const currentLocation = useLocation();
  const history = useHistory();
  const authenticated = !!useAuthentication()[0];
  const [loading, setLoading] = useState(false);
  const [person, setPerson] = useState<Person | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const lookupResult = await lookupPerson(personId);

        // Handle redirection.
        if (lookupResult.redirect_url) {
          const redirectUrl = lookupResult.redirect_url;
          const url = redirectUrl.startsWith("http")
            ? redirectUrl
            : "https://" + redirectUrl;
          window.location.replace(url);
        } else if (authenticated) {
          const person = await getPerson(lookupResult.id);
          setPerson(person);
        }
      } catch (err) {
        console.log(err);
        history.replace(`/${personId}/legacy`);
      } finally {
        setLoading(false);
      }
    })();
  }, [authenticated, personId, history]);

  if (loading)
    return (
      <Box mt={4} textAlign="center">
        <CircularProgress />
      </Box>
    );
  return (
    <Container>
      <Box py={3}>
        <Paper>
          {person && (
            <>
              <Box p={2} bgcolor="grey.200">
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Avatar src={""} alt="Person photo" />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">
                      {person?.first_name} {person?.last_name}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      {person?.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box p={2}>
                <Typography variant="h6">
                  <span className="text-grey">Name:</span>{" "}
                  <span>
                    {person?.first_name} {person?.last_name}
                  </span>
                </Typography>
                <Typography variant="h6">
                  <span className="text-grey">Email Address:</span>{" "}
                  <a href={`mailto:${person?.email}`}>{person?.email}</a>
                </Typography>
                <Typography variant="h6">
                  <span className="text-grey">Phone Number:</span>{" "}
                  <span>+{person?.phone}</span>
                </Typography>
              </Box>
            </>
          )}
          <Divider light />
          {authenticated && <Box></Box>}
          {!authenticated && (
            <Box p={2}>
              <Typography align="center" gutterBottom>
                Please login to verify identity.
              </Typography>
              <Box textAlign="center">
                <Link
                  underline="none"
                  component={RouterLink}
                  to={{pathname: "/login", state: {referrer: currentLocation}}}
                >
                  <Button variant="contained" color="primary">
                    Login
                  </Button>
                </Link>
              </Box>
            </Box>
          )}
        </Paper>
      </Box>
    </Container>
  );
};

export default PersonTemplate;
