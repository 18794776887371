// function to open a new url in a new tab
export const openInNewTab = (url: string) => {
  if (typeof window === "object") window?.open(url, "_blank");
};

// function to get baseurl : @params: {isOrderTrack}
export const getBaseUrl = () => {
  return process.env.NODE_ENV === "development"
    ? window.location.hostname
    : window.location.href.includes("dev")
    ? `https://dev.3hd.us/`
    : `https://3hd.us/`;
};

export function isObjectEmpty(obj: object): boolean {
  return Object.keys(obj).length === 0;
}

export const IS_DEV_MODE =
  process.env.NODE_ENV === "development" ||
  window.location.href.includes("dev.3hd.us") ||
  window.location.href.includes("moment-");
