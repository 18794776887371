import React from "react";
import ReactDOM from "react-dom";
import {Provider as ReduxProvider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import "./core/styles/index.scss";
import App from "./App";
import {createBrowserHistory} from "history";
import reduxStore, {persistor} from "./core/redux/store";
import "./style.css";

const history = createBrowserHistory();
let app = document.getElementById("root");
if (app) {
  // START: Cloudfront url hashbang fix.
  // 1. Set up the browser history with the updated location
  // (minus the # sign)
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  // 2. Redirect to actual path on the client side
  if (path) {
    history.replace({pathname: path, hash: ""});
  }

  ReactDOM.render(
    <ReduxProvider store={reduxStore}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </ReduxProvider>,
    app
  );
}
