
import { useFormik, FormikConfig } from 'formik';
import * as Yup from 'yup';

export interface TrainingInput {
  title: string;
  description: string;
  instructions: string[];
  video_url: string;
}

export type TrainingInputFormConfig = Omit<FormikConfig<Partial<TrainingInput>>, 'validationSchema' | 'initialValues'> & {initialValues?: Partial<TrainingInput>};

export const validationSchema = Yup.object({
  title: Yup.string().trim().required('Required.'),
  description: Yup.string().trim().notRequired(),
  instructions: Yup.array().of(Yup.string()),
  video_url: Yup.string().required('Training video is required.')
});

const DEFAULT_TRAINING_INPUT:TrainingInput = {
  title: '',
  description: '',
  instructions: [],
  video_url: ''
};

const useTrainingInputForm = ({ initialValues, ...formikConfig }:TrainingInputFormConfig) => {
  return useFormik({
    initialValues: Object.assign({}, DEFAULT_TRAINING_INPUT, initialValues),
    validationSchema,
    ...formikConfig
  });
};

export default useTrainingInputForm;
