import React, {useEffect, useState, useRef} from "react";
import {LicensePlateTemplateProps} from "./types";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import SvgIcon from "@material-ui/core/SvgIcon";
import MomenttrackIdTag from "../../core/components/MomenttrackIdTag";
import DateTag from "../../core/components/DateTag";
import LicensePlateActivityLogs, {
  LicensePlateActivityLogsApi,
} from "./LicensePlateActivityLogs";
import {
  lookupLicensePlate,
  getPublicLicensePlate,
  LicensePlate,
} from "../../core/system/license-plates";
import {useAuthentication} from "../../core/providers/AuthenticationProvider";
import {useHistory, useLocation} from "react-router-dom";
import {ReactComponent as ProductIconSvg} from "../../assets/icons/product.svg";
import momenttrackLogo from "../../assets/images/logo.png";
import unknownQrCodeImage from "../../assets/images/unknown_qr_code.png";

export const NoPermissionsTemplate = ({
  title = "Access denied!",
  text = "You do not have permission to view this page, please",
}: {
  title?: string;
  text?: string;
}) => {
  const currentLocation = useLocation();

  return (
    <Container maxWidth="sm">
      <Box pt={10} mb={4} textAlign="center">
        <img
          alt="Unknown qr code"
          src={unknownQrCodeImage}
          style={{maxWidth: "100%", maxHeight: "250px"}}
        />
      </Box>
      <Typography variant="h5" gutterBottom align="center">
        {title}
      </Typography>
      <Typography
        color="textSecondary"
        align="center"
        style={{fontFamily: "monospace"}}
      >
        {text}
        <Link
          component={RouterLink}
          to={{
            pathname: "/login",
            state: {referrer: currentLocation},
          }}
          style={{margin: "0 0.5rem", textDecoration: "underline"}}
        >
          Login
        </Link>
        for private data
      </Typography>
    </Container>
  );
};

const PublicLicensePlateTemplate: React.FC<
  LicensePlateTemplateProps
> = props => {
  const {licensePlateId} = props;
  const activityLogs = useRef<LicensePlateActivityLogsApi>(null);
  const [licensePlate, setLicensePlate] = useState<LicensePlate | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentUser] = useAuthentication();
  const authenticated = !!currentUser;
  const history = useHistory();
  const currentLocation = useLocation();
  const [licenseplate_id, setlicenseplate_id] = useState<number>();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const lookupResult = await lookupLicensePlate(licensePlateId);
        const licensePlate = await getPublicLicensePlate(lookupResult.id);
        setLicensePlate(licensePlate);
        setlicenseplate_id(lookupResult.id);
      } catch (err) {
        history.replace(`/${licensePlateId}/legacy`);
      } finally {
        setLoading(false);
      }
    })();
  }, [history, licensePlateId]);

  const convertToBoolean = (param: any) => {
    return param ? true : false;
  };

  const Permissions = !!licensePlate
    ? {
        product: typeof licensePlate.product === "object",
        quantity: Boolean(licensePlate?.quantity?.toString()),
        created_at: convertToBoolean(licensePlate?.created_at),
        external_serial_number:
          licensePlate?.external_serial_number === null ||
          convertToBoolean(licensePlate?.external_serial_number),
        show_logs: convertToBoolean(licensePlate.show_logs),
      }
    : false;

  return (
    <>
      {loading && <LinearProgress />}
      <Container>
        <Box py={4}>
          {!!licensePlate && (
            <>
              <Grid container>
                <Grid item xs={12} md={licensePlate.show_logs ? 4 : 12}>
                  <Box bgcolor="grey.50" style={{height: "100%"}}>
                    <Box px={2} py={1} bgcolor="grey.200">
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Typography variant="h3" style={{lineHeight: 0}}>
                            <SvgIcon fontSize="inherit">
                              <ProductIconSvg />
                            </SvgIcon>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h6">
                            Product{" "}
                            <MomenttrackIdTag
                              id={licensePlateId}
                              type="license_plate"
                            />
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {process.env.REACT_APP_QR_BASENAME || "3hd.us"}
                            /...
                            {licensePlateId.slice(-5)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    {typeof Permissions !== "boolean" &&
                    Object.values(Permissions).some(el => el) ? (
                      <Box p={2}>
                        <Grid container spacing={4}>
                          {typeof licensePlate.product !== "number" && (
                            <>
                              {licensePlate?.product?.part_number && (
                                <Grid item xs={12} sm={4} md={12}>
                                  <Typography
                                    variant="overline"
                                    color="textSecondary"
                                  >
                                    Part #
                                  </Typography>
                                  <Typography
                                    style={{
                                      maxWidth: "100%",
                                      wordWrap: "break-word",
                                    }}
                                    variant="body1"
                                  >
                                    {licensePlate.product?.part_number || "--"}
                                  </Typography>
                                </Grid>
                              )}
                              {licensePlate?.product?.description && (
                                <Grid item xs={12} sm={4} md={12}>
                                  <Typography
                                    variant="overline"
                                    color="textSecondary"
                                  >
                                    Description
                                  </Typography>
                                  <Typography variant="body1">
                                    {licensePlate.product?.description || "--"}
                                  </Typography>
                                </Grid>
                              )}
                            </>
                          )}
                          {Permissions?.quantity && (
                            <Grid item xs={12} sm={4} md={12}>
                              <Typography
                                variant="overline"
                                color="textSecondary"
                              >
                                Quantity
                              </Typography>
                              <Typography variant="body1">
                                {licensePlate.quantity}
                              </Typography>
                            </Grid>
                          )}
                          {Permissions?.created_at && (
                            <Grid item xs={12} sm={4} md={12}>
                              <Typography
                                variant="overline"
                                color="textSecondary"
                              >
                                Intake date
                              </Typography>
                              <Typography variant="body1">
                                <DateTag
                                  date={licensePlate.created_at}
                                  dateInputType="utc"
                                />
                              </Typography>
                            </Grid>
                          )}
                          {Permissions.external_serial_number && (
                            <Grid item xs={12} sm={4} md={12}>
                              <Typography
                                variant="overline"
                                color="textSecondary"
                              >
                                Asset#
                              </Typography>
                              <Typography variant="body1">
                                {licensePlate.external_serial_number || "--"}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    ) : (
                      <Box p={2}>
                        <NoPermissionsTemplate
                          title="No Permissions Set"
                          text="There are no permissions set for this license plate, as it has been blocked by an administrator."
                        />
                      </Box>
                    )}
                  </Box>
                </Grid>
                {licensePlate.show_logs && licenseplate_id && (
                  <Grid item xs={12} md={8}>
                    <Paper
                      square
                      elevation={0}
                      style={{
                        height: "100%",
                      }}
                    >
                      <LicensePlateActivityLogs
                        key={licensePlateId}
                        isPublic
                        ref={activityLogs}
                        licensePlateId={licenseplate_id!}
                        onGetLogs={() => {}}
                      />
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </>
          )}

          {!authenticated && !loading && (
            <Box
              px={2}
              py={1}
              textAlign="center"
              style={{
                backgroundColor: "#ffffff",
                backgroundImage:
                  "repeating-linear-gradient(-45deg, rgba(255, 255, 255, 0) 0px, rgba(255, 255, 255, 0) 10px, rgba(0, 0, 0, 0.025) 10px, rgba(0, 0, 0, 0.025) 20px)",
              }}
            >
              <Typography component="div" variant="caption">
                <i style={{marginRight: "0.5rem"}}>
                  Public QR by
                  <a
                    href="https://www.momenttrack.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="Powered by momenttrack"
                      src={momenttrackLogo}
                      style={{
                        maxHeight: 16,
                        verticalAlign: "middle",
                        marginLeft: "8px",
                      }}
                    />
                  </a>
                </i>
                or
                <Link
                  component={RouterLink}
                  to={{
                    pathname: "/login",
                    state: {referrer: currentLocation},
                  }}
                  style={{margin: "0 0.5rem"}}
                >
                  Login
                </Link>
                for private data
              </Typography>
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
};

export default PublicLicensePlateTemplate;
