import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from "react";
import axios from "axios";
import {
  completeMultipartUpload,
  generatePresignedUrl,
  initiateMultipartUpload,
  uploadPart,
} from "../../system/fileupload";

interface FileUploaderProps {
  required?: boolean;
  filetype?: string;
  inputId?: string;
  initialValue?: string;
  children?: (props: {
    fileInput: JSX.Element;
    selectedFile: File | null;
    selectedFileURL: string | null;
    isUploading: boolean;
    progress: number;
    fileInputRef?: React.RefObject<HTMLInputElement>;
    clearSelection: () => void;
  }) => React.ReactNode;
}

interface FileUploaderRef {
  validate: () => boolean;
  clearSelection: () => void;
  startUpload: () => Promise<string | undefined>;
}

const FileUploader: React.ForwardRefRenderFunction<
  FileUploaderRef,
  FileUploaderProps
> = (props, ref) => {
  const {inputId, filetype, required, children} = props;

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileURL, setSelectedFileURL] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const CHUNK_SIZE = 5 * 1024 * 1024;
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSelectedFile(file || null);
    if (file) {
      setSelectedFileURL(window.URL.createObjectURL(file));
    }
  };
  const startUpload = useCallback(async () => {
    if (!selectedFile) {
      return Promise.reject(new Error("Please choose a file to upload."));
    }

    const uploadFile = async (file: File) => {
      // Step 1: Initiate multipart upload
      const initiateResponse = await initiateMultipartUpload(file.name);
      const uploadId = initiateResponse.upload_id;
      const filename = initiateResponse.filename;
      const s3_path = initiateResponse.s3_path;

      // Step 2: Generate presigned URLs and upload parts
      const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
      const parts = [];
      let uploadedBytes = 0;

      const cancelTokenSource = axios.CancelToken.source();
      for (let partNumber = 1; partNumber <= totalChunks; partNumber++) {
        const startByte = (partNumber - 1) * CHUNK_SIZE;
        const endByte = Math.min(partNumber * CHUNK_SIZE, file.size);

        const chunk = file.slice(startByte, endByte);

        // Generate presigned URL for the chunk
        const presignedUrl = await generatePresignedUrl(
          filename,
          uploadId,
          partNumber
        );

        // Upload the chunk
        const {response, etag} = await uploadPart(presignedUrl, chunk, {
          // eslint-disable-next-line no-loop-func
          onUploadProgress: progressEvent => {
            // Calculate the progress
            uploadedBytes = startByte + progressEvent.loaded;
            const progress = Math.round((uploadedBytes / file.size) * 100);
            setProgress(progress);
          },
          cancelToken: cancelTokenSource.token,
        });
        // Track the part details
        parts.push({PartNumber: partNumber, ETag: etag});

        // // Check if upload was canceled
        if (response.data.status === "canceled") {
          throw new Error("Issue uploading the chunk...");
        }
      }

      // Step 3: Complete multipart upload
      await completeMultipartUpload(filename, uploadId, parts);

      return s3_path;
    };

    try {
      setIsUploading(true);
      const file_url = await uploadFile(selectedFile);
      return file_url;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setIsUploading(false);
    }
  }, [CHUNK_SIZE, selectedFile]);

  const clearSelection = () => {
    setSelectedFile(null);
    setSelectedFileURL("");
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input
    }
  };
  useImperativeHandle(
    ref,
    () => ({
      validate: () => {
        return !required || !!selectedFile;
      },
      hasFile: () => !!selectedFile,
      clearSelection,
      startUpload,
    }),
    [required, selectedFile, startUpload]
  );

  return (
    <div style={{position: "relative"}}>
      {children
        ? children({
            fileInput: (
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                accept={filetype}
                id={inputId}
                style={{
                  position: "absolute",
                  inset: 0,
                  opacity: 0,
                  cursor: "pointer",
                }}
              />
            ),
            // Pass other relevant states or handlers as needed
            selectedFile,
            selectedFileURL,
            isUploading,
            progress,
            fileInputRef,
            clearSelection,
          })
        : null}{" "}
    </div>
  );
};

export default forwardRef(FileUploader);
