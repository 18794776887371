import React, {useEffect, useState} from "react";
import {DocumentTemplateProps} from "./types";
import LinearProgress from "@material-ui/core/LinearProgress";
import TrainingDocumentTemplate from "./TrainingDocumentTemplate";
import ProductionOrderDocumentTemplate from "./ProductionOrderDocumentTemplate";
import {useHistory} from "react-router-dom";
import {ProductionOrderLookupResult} from "../../core/system/production-orders";
import {TrainingDocument} from "../../core/system/litmon";
import {lookupDocument} from "../../core/system/documents";
import {useAuthentication} from "../../core/providers/AuthenticationProvider";
import PublicProductionOrderTemplate from "./ProductionOrderDocumentTemplate/PublicProductionOrderTemplate";
import {NoPermissionsTemplate} from "../LicensePlateTemplate/PublicLicensePlateTemplate";

const DocumentTemplate: React.FC<DocumentTemplateProps> = ({docid}) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [lookupResult, setLookupResult] = useState<
    ProductionOrderLookupResult | TrainingDocument | null
  >(null);
  const authenticated = !!useAuthentication()[0];

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const lookupResult = await lookupDocument(docid);
        // TODO: Temporary check, if the document is from firebase and of old format (old format doesnt have property `pick_type`)
        if (!lookupResult.pick_type) throw new Error("Document not found.");

        if (lookupResult.redirect_url) {
          const redirectUrl = lookupResult.redirect_url;
          const url = redirectUrl.startsWith("http")
            ? redirectUrl
            : "https://" + redirectUrl;
          window.location.replace(url);
        } else setLookupResult(lookupResult);
      } catch (err) {
        history.replace(`/${docid}/legacy`);
      } finally {
        setLoading(false);
      }
    })();
  }, [docid, history]);

  if (loading) return <LinearProgress />;

  if (!lookupResult) return <>Document not found.</>;
  if (!lookupResult.pick_type) return <>Missing document type.</>;

  switch (lookupResult.pick_type.toUpperCase()) {
    case "PRODUCTIONORDER":
      return !authenticated &&
        ((lookupResult?.public_view_permissions || 0) & 128) === 128 ? (
        <PublicProductionOrderTemplate orderId={lookupResult.id} />
      ) : !authenticated ? (
        <NoPermissionsTemplate />
      ) : (
        <ProductionOrderDocumentTemplate orderId={lookupResult.id} />
      );
    case "TRAINING":
      return (
        <TrainingDocumentTemplate
          trainingDocument={lookupResult as TrainingDocument}
        />
      );
    default:
      return <>Unknown document type "{lookupResult.pick_type}".</>;
  }
};

export default DocumentTemplate;
