import React, {
  useState,
  useMemo,
  useLayoutEffect,
  useEffect,
  useRef,
} from "react";
import {IPageData, MoveAllDialogApi, PlaceReportProps} from "./types";
import clsx from "clsx";
import moment from "moment/moment";
import dataToCsv from "../../../core/system/utils/data-to-csv";
import styles from "./styles";
import {makeStyles} from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import SvgIcon from "@material-ui/core/SvgIcon";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import {EmailShareButton} from "react-share";
import DateTag from "../../../core/components/DateTag";
import QrCodeInput from "../../../core/components/QrCodeInput";
import PieChart from "../../../core/components/PieChart";
import useNotify from "../../../core/hooks/use-notify";
import {
  getPlaceReport,
  IPlaceReport,
  IPlaceActivityLog,
  generateSheets,
} from "../../../core/system/places";
import {ReactComponent as ProductSvg} from "../../../assets/icons/product.svg";
import {ReactComponent as PlaceSvg} from "../../../assets/icons/place.svg";
import {ReactComponent as PersonSvg} from "../../../assets/icons/person.svg";
import {ReactComponent as ClockSvg} from "../../../assets/icons/clock.svg";
import EmbeddedContentDialog, {
  EmbeddedContentDialogApi,
} from "../../../core/components/EmbeddedContentDialog";
import {Footer} from "@momenttrack/react/base/Layout";
import {TablePagination} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MoveAllDialog from "./MoveAllDialog";
const RELOAD_INTERVAL = 15 * 60 * 1000;
const useStyles = makeStyles(styles);
const ROWS_PER_PAGE = 50;

const PlaceReport: React.FC<PlaceReportProps> = ({
  placeId,
  placeQR,
  setLoading,
}) => {
  const notify = useNotify();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [loadingReport, setLoadingReport] = useState(false);
  const [showOnlyActiveLogs, setShowOnlyActiveLogs] = useState(true);
  const [reportData, setReportData] = useState<IPlaceReport | null>(null);
  const [chartSvg, setChartSvg] = useState<string | null>(null);
  const [pageData, setPageData] = useState<IPageData | null>(null);
  const [pdfURL, setPdfURL] = useState<string | null>(null);
  const pdfSheetsDialog = useRef<EmbeddedContentDialogApi>(null);
  const moveallDialog = useRef<MoveAllDialogApi>(null);

  // pagination params state => can be updated to fit other query params
  const [paginationParams, setpaginationParams] = useState<{
    page: number;
  }>({
    page: 0,
  });

  const licensePlates = reportData?.license_plates;
  const logs = reportData?.logs;

  const productsMap = useMemo(() => {
    if (!logs) return {};

    return logs.reduce((map, log) => {
      map[log.product.part_number] = log.product;
      return map;
    }, {} as {[pn: string]: any});
  }, [logs]);

  const licensePlatesMap = useMemo(() => {
    if (!licensePlates) return {};

    return licensePlates.reduce((map, lp) => {
      const pn = lp.product.part_number;
      const currData = map[pn] || {
        product: productsMap[pn] || lp.product,
        total_qty: 0,
        license_plates: [],
      };

      currData.total_qty += lp.quantity;
      currData.license_plates.push(lp);
      map[pn] = currData;
      return map;
    }, {} as any);
  }, [licensePlates, productsMap]);

  const checkIfLocationResides = (log: IPlaceActivityLog) => {
    if (!licensePlates) return false;
    const lpIds = new Set(licensePlates.map(lp => lp.id));

    return lpIds.has(log.license_plate_id);
  };

  const currentLogs = useMemo(() => {
    if (!licensePlates || !logs) return [];

    const lpIds = new Set(licensePlates.map(lp => lp.id));
    return logs
      .sort(
        (a, b) =>
          moment.utc(b.arrived_at).valueOf() -
          moment.utc(a.arrived_at).valueOf()
      )
      .reduce((reduced, log) => {
        const correspondingLp = licensePlates.find(
          el => el.id === log.license_plate_id
        );

        if (lpIds.has(log.license_plate_id)) {
          reduced.push({
            ...log,
            license_plate: {
              ...log.license_plate,
              external_serial_number:
                correspondingLp?.external_serial_number || "--",
            },
          });
          lpIds.delete(log.license_plate_id);
        }

        return reduced;
      }, [] as IPlaceActivityLog[]);
  }, [licensePlates, logs]);

  const allLogs = useMemo(() => {
    if (!licensePlates || !logs) return [];

    return logs.reduce((reduced, log) => {
      const correspondingLp = licensePlates.find(
        el => el.id === log.license_plate_id
      );
      reduced.push({
        ...log,
        license_plate: {
          ...log.license_plate,
          external_serial_number:
            correspondingLp?.external_serial_number || "--",
        },
      });

      return reduced;
    }, [] as IPlaceActivityLog[]);
  }, [licensePlates, logs]);

  const qtyPresent = useMemo(() => {
    if (!licensePlates) return 0;

    return licensePlates.reduce((total, lp) => {
      return (total += lp.quantity);
    }, 0);
  }, [licensePlates]);

  const distByPartNumber = useMemo(() => {
    // const _partNumberProductMap: {[key: string]: any} = {};
    // const _partNumberQtyMap: {[key: string]: number} = {};
    // let _totalQty = 0;

    // Using currentLogs instead of reportData.license_plates because description is missing in former. P.S Should be using the later one.
    // currentLogs.forEach(log=>{
    //   _partNumberProductMap[log.product.part_number] = log.product;
    //   _partNumberQtyMap[log.product.part_number] = (_partNumberQtyMap[log.product.part_number] || 0) + log.license_plate.quantity;
    //   _totalQty += log.license_plate.quantity;
    // });

    return Object.keys(licensePlatesMap).map(pn => {
      return {
        part_number: pn,
        product: licensePlatesMap[pn].product,
        quantity: licensePlatesMap[pn].total_qty,
        percentage: licensePlatesMap[pn].total_qty / qtyPresent,
      };
    });
  }, [licensePlatesMap, qtyPresent]);
  const partNumberDistSeries = useMemo(() => {
    return [
      {
        name: "Quantity",
        colorByPoint: true,
        type: "pie",
        data: distByPartNumber.map(dist => {
          return {
            name: dist.part_number,
            y: dist.quantity,
          } as any;
        }),
      },
    ];
  }, [distByPartNumber]);

  useLayoutEffect(() => {
    let timeoutHandle: NodeJS.Timeout;

    (async function load() {
      timeoutHandle = setTimeout(load, RELOAD_INTERVAL);

      try {
        setLoadingReport(true);
        setLoading(true);
        const reportData = await getPlaceReport(+placeId, {
          ...paginationParams,
          page: paginationParams.page + 1,
        });
        reportData.logs?.sort(
          (a, b) =>
            moment.utc(b.arrived_at).valueOf() -
            moment.utc(a.arrived_at).valueOf()
        );
        setReportData(reportData);
        setLoadingReport(false);
      } catch (err) {
        notify(err as Error);
        setLoadingReport(false);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      clearTimeout(timeoutHandle);
    };
  }, [placeId, notify, paginationParams, setLoading]);

  // Adding data for pdf rendering
  useEffect(() => {
    if (
      !reportData ||
      !logs ||
      !licensePlates ||
      !productsMap ||
      !qtyPresent ||
      !distByPartNumber ||
      !chartSvg
    )
      return;

    setPageData({
      reportData,
      logs,
      licensePlates,
      productsMap,
      currentLogs,
      qtyPresent,
      distByPartNumber,
      partNumberDistSeries,
      chartSvg,
      placeQR,
    });
  }, [
    reportData,
    logs,
    licensePlates,
    productsMap,
    currentLogs,
    qtyPresent,
    distByPartNumber,
    partNumberDistSeries,
    chartSvg,
    placeQR,
  ]);

  const classes = useStyles();

  const handleGetSvg = (svg: string) => {
    setChartSvg(svg);
  };

  const handleGetDialogContent = (url: string) => {
    setPdfURL(url);
  };

  const handleShareButton = () => {
    if (!pageData) return;
    pdfSheetsDialog.current?.openDialog(() => generateSheets(pageData));
  };
  const handleMoveAllButton = () => {
    // if (!pageData) return;
    moveallDialog.current?.openDialog();
  };
  return (
    <>
      {loadingReport && (
        <Box textAlign="center" py={2}>
          <CircularProgress size={24} />
        </Box>
      )}
      {!!reportData && (
        <Grid container spacing={3} id="place-report-page">
          <Grid item xs={12} lg={3}>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item>
                <img
                  alt="place qr code"
                  src={`https://api.3hd.us/app/qrcode_gen.create?data=3hd.us/${placeQR}`}
                  style={{width: "0.75in"}}
                />
              </Grid>
              <Grid item>
                <Typography
                  component="div"
                  variant="caption"
                  color="textSecondary"
                >
                  PLACE
                </Typography>
                <Typography variant="h4">{reportData.name}</Typography>
              </Grid>
            </Grid>
            <Box my={2} mb={4}>
              <QrCodeInput
                placeholder="LINK (Scan/Paste QR code here)"
                onChange={window.alert}
                disabled={
                  false /** Temporarily set to false. Set accordingly when functionality is integrated. */
                }
                autoFocus
              />
            </Box>
            <Hidden xsDown>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4} lg={12}>
                  <Typography
                    component="div"
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                  >
                    DATA AS OF
                  </Typography>
                  <Typography variant="h6">
                    {moment().format("MMM DD, YYYY - hh:mma")}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={12}>
                  <Typography
                    component="div"
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                  >
                    AVERAGE DURATION
                  </Typography>
                  <Typography variant="h6">
                    {reportData.average_duration
                      ? moment
                          .duration(reportData.average_duration, "s")
                          .humanize()
                      : "--"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={12}>
                  <Typography
                    component="div"
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                  >
                    QTY IN STATION
                  </Typography>
                  <Typography variant="h6">{qtyPresent}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={12}>
                  <Typography
                    component="div"
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                  >
                    OLDEST ITEM
                  </Typography>
                  <Link
                    variant="h6"
                    href={`/${reportData.oldest_license_plate?.lp_id}`}
                    target="_BLANK"
                    underline="always"
                  >
                    {reportData?.oldest_license_plate?.product.part_number ||
                      "--"}
                  </Link>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>

          <Grid item xs={12} lg={9}>
            <Box component={Paper} mb={2}>
              <Tabs
                value={activeTabIndex}
                onChange={(ev, newIndex) => setActiveTabIndex(newIndex)}
              >
                <Tab
                  label="Summary"
                  className={clsx({
                    [classes.activeTab]: activeTabIndex === 0,
                  })}
                />
                <Tab
                  label="Contains"
                  className={clsx({
                    [classes.activeTab]: activeTabIndex === 1,
                  })}
                />
                <Tab
                  label="Logs"
                  className={clsx({
                    [classes.activeTab]: activeTabIndex === 2,
                  })}
                />
              </Tabs>
              <Divider light />

              {/* BEGIN: Place summary */}
              {activeTabIndex === 0 && (
                <Box p={2}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={6} lg={5}>
                      <PieChart
                        series={partNumberDistSeries}
                        getSvg={handleGetSvg}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={7}>
                      <TableContainer>
                        <Table stickyHeader size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <b>PART #</b>
                              </TableCell>
                              <TableCell>
                                <b>QTY</b>
                              </TableCell>
                              <TableCell>
                                <b>DESCRIPTION</b>
                              </TableCell>
                              <TableCell>
                                <b>%</b>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {distByPartNumber.map((dist, i) => (
                              <TableRow
                                key={`${i}-${dist.part_number}-${dist.quantity}`}
                                hover
                              >
                                <TableCell>{dist.part_number}</TableCell>
                                <TableCell>{dist.quantity}</TableCell>
                                <TableCell>
                                  {dist.product.description}
                                </TableCell>
                                <TableCell>
                                  {(dist.percentage * 100).toFixed(2)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {/* END: Place summary */}

              {/* BEGIN: Contains */}
              {activeTabIndex === 1 && (
                <Box p={2}>
                  {licensePlates && licensePlates.length > 0 && (
                    <Typography variant="body1" gutterBottom>
                      Following are the products present at this place.
                    </Typography>
                  )}
                  {(!licensePlates || licensePlates.length === 0) && (
                    <Typography variant="body1" gutterBottom>
                      There aren't any products at this place.
                    </Typography>
                  )}
                  <Box py={3}>
                    <Grid container spacing={4}>
                      {licensePlates &&
                        licensePlates.map((lp, i) => (
                          <Grid
                            key={`${lp.id}-${i}`}
                            item
                            xs={12}
                            sm={4}
                            lg={3}
                          >
                            <Link
                              target="_BLANK"
                              variant="h6"
                              href={`/${lp.lp_id}`}
                            >
                              {process.env.REACT_APP_QR_BASENAME || "3hd.us"}
                              /...{lp.lp_id.slice(-5)}
                            </Link>
                            <Typography variant="caption" display="block">
                              {lp.product.part_number}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              {productsMap[lp.product.part_number]
                                ?.description || "--"}
                            </Typography>
                          </Grid>
                        ))}
                    </Grid>
                  </Box>
                </Box>
              )}
              {/* END: Contains */}
            </Box>

            <Hidden smUp>
              <Paper>
                <Box p={2} my={4}>
                  <Box mb={4}>
                    <Typography variant="h5">Stats</Typography>
                  </Box>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4} lg={12}>
                      <Typography
                        component="div"
                        variant="caption"
                        color="textSecondary"
                        gutterBottom
                      >
                        DATA AS OF
                      </Typography>
                      <Typography variant="h6">
                        {moment().format("MMM DD, YYYY - hh:mma")}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={12}>
                      <Typography
                        component="div"
                        variant="caption"
                        color="textSecondary"
                        gutterBottom
                      >
                        AVERAGE DURATION
                      </Typography>
                      <Typography variant="h6">
                        {reportData.average_duration
                          ? moment
                              .duration(reportData.average_duration, "s")
                              .humanize()
                          : "--"}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={12}>
                      <Typography
                        component="div"
                        variant="caption"
                        color="textSecondary"
                        gutterBottom
                      >
                        QTY IN STATION
                      </Typography>
                      <Typography variant="h6">{qtyPresent}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={12}>
                      <Typography
                        component="div"
                        variant="caption"
                        color="textSecondary"
                        gutterBottom
                      >
                        OLDEST ITEM
                      </Typography>
                      <Link
                        variant="h6"
                        href={`/${reportData.oldest_license_plate?.lp_id}`}
                        target="_BLANK"
                        underline="always"
                      >
                        {reportData?.oldest_license_plate?.product
                          .part_number || "--"}
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Hidden>

            {/* BEGIN: Logs */}
            <Paper>
              <Box px={2} py={1}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs="auto">
                    <Typography variant="h5">Logs</Typography>
                  </Grid>
                  <Grid item xs />
                  <Grid item xs="auto">
                    <FormControlLabel
                      label="Show active products only"
                      control={
                        <Switch
                          checked={showOnlyActiveLogs}
                          onChange={ev =>
                            setShowOnlyActiveLogs(ev.target.checked)
                          }
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <IconButton
                      disabled={!pageData}
                      aria-label="share via mail"
                      color="secondary"
                      onClick={handleShareButton}
                    >
                      <EmailOutlinedIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs="auto">
                    <IconButton
                      color="secondary"
                      onClick={() =>
                        dataToCsv(
                          (showOnlyActiveLogs
                            ? currentLogs
                            : allLogs || []
                          ).map(l => ({
                            "asset number":
                              l.license_plate.external_serial_number || "N/A",
                            "activity id": l.activity_id,
                            "arrived at": l.arrived_at,
                            quantity: l.license_plate.quantity,
                            "product id": l.product.id,
                            product: l.product.description,
                            "product qr": `3hd.us/${l.license_plate.lp_id}`,
                            "part number": l.product.part_number,
                            "person id": l.user.id,
                            person: l.user.first_name,
                            "person qr": `3hd.us/${l.user.person_id}`,
                          })),
                          `logs-${reportData.name}-${reportData.id}-page-${
                            paginationParams.page + 1
                          }`
                        )
                      }
                      disabled={
                        loadingReport ||
                        !reportData.logs ||
                        reportData.logs.length === 0
                      }
                    >
                      <Icon>download</Icon>
                    </IconButton>
                  </Grid>
                  <Grid item xs="auto">
                    <Button
                      startIcon={<ExitToAppIcon />}
                      variant="outlined"
                      color="secondary"
                      onClick={handleMoveAllButton}
                    >
                      Move all
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Divider light />
              <TableContainer>
                <Table>
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        <b>EXT. SN</b>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <b>PART NUMBER</b>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <SvgIcon>
                          <ProductSvg />
                        </SvgIcon>{" "}
                        <b>PRODUCT QR</b>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <b>DESCRIPTION</b>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <b>QUANTITY</b>
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        <SvgIcon>
                          <PlaceSvg />
                        </SvgIcon>{" "}
                        <b>CURRENTLY RESIDES</b>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <SvgIcon>
                          <PersonSvg />
                        </SvgIcon>{" "}
                        <b>PERSON</b>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <SvgIcon>
                          <ClockSvg />
                        </SvgIcon>{" "}
                        <b>ARRIVED</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {(showOnlyActiveLogs ? currentLogs : allLogs || []).map(
                      log => (
                        <TableRow key={log.license_plate_id + log.arrived_at}>
                          <TableCell className={classes.tableCell}>
                            {log.license_plate.external_serial_number || "--"}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {log.product.part_number}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <Link
                              color="textPrimary"
                              href={`/${log?.license_plate?.lp_id}`}
                              target="_BLANK"
                            >
                              {process.env.REACT_APP_QR_BASENAME || "3hd.us"}
                              /...{log?.license_plate?.lp_id?.slice(-5)}
                            </Link>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {log.product.description || "--"}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {log.license_plate.quantity}
                          </TableCell>
                          <TableCell
                            align="center"
                            padding="checkbox"
                            className={classes.tableCell}
                          >
                            {checkIfLocationResides(log) ? (
                              <Icon color="primary">check</Icon>
                            ) : (
                              <Icon color="error">clear</Icon>
                            )}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <Link
                              href={`/${log.user.person_id}`}
                              target="_BLANK"
                            >
                              {log.user.first_name}
                            </Link>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <DateTag
                              date={log.arrived_at}
                              dateInputType="utc"
                            />
                          </TableCell>
                        </TableRow>
                      )
                    )}

                    {(showOnlyActiveLogs ? currentLogs : logs || []).length ===
                      0 && (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Typography variant="body2" color="textSecondary">
                            No logs found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Footer
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1rem",
                  flexWrap: "wrap",
                }}
                textAlign="right"
                bgcolor="grey.200"
              >
                <p>
                  Showing page {paginationParams.page + 1} out of{" "}
                  {Math.ceil(reportData.logs_total / ROWS_PER_PAGE)}
                </p>
                <TablePagination
                  component="div"
                  count={reportData.logs_total}
                  rowsPerPage={ROWS_PER_PAGE}
                  rowsPerPageOptions={[]}
                  page={paginationParams.page}
                  onPageChange={(ev, page) => {
                    setpaginationParams(prev => ({
                      ...prev,
                      page,
                    }));
                  }}
                />
              </Footer>
            </Paper>
            {/* END: Logs */}
          </Grid>
        </Grid>
      )}
      <EmbeddedContentDialog
        ref={pdfSheetsDialog}
        title="Place report"
        onCompleted={handleGetDialogContent}
        specificBtn={true}
      >
        <Button component="div" variant="contained" color="secondary">
          <EmailShareButton
            url={pdfURL ? pdfURL : ""}
            disabled={pdfSheetsDialog?.current?.loadingContent}
          >
            Share
          </EmailShareButton>
        </Button>
      </EmbeddedContentDialog>
      <MoveAllDialog ref={moveallDialog} sourceId={placeId} />
    </>
  );
};

export default PlaceReport;
