import React from "react";
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import Box from "@material-ui/core/Box";
import {useAuthentication} from "./core/providers/AuthenticationProvider";
import MainLayout from "./MainLayout";
import ProtectedRoute from "./core/components/ProtectedRoute";
import Login from "./Login";
import LandingPage from "./LandingPage";
import IdLookupLegacy from "./IdLookupLegacy";
import IdLookup from "./IdLookup";
import GuestTrack from "./Guesttrack";
import LearningInTheMomentLegacy from "./LearningInTheMomentLegacy";
import LearningInTheMoment from "./LearningInTheMoment";
import PrintActivities from "./PrintActivities";
import WMS from "./WMS";
import Digikey from "./Digikey/Digikey";
import Mouser from "./Digikey/Mouser";
import GovPropLabelGenerator from "./GovPropLabelGenerator";
import DataMatrixTemplate from "./IdLookup/DataMatrixTemplate";
import ProductionOrderDemoTemplate from "./IdLookup/DocumentTemplate/ProductionOrderDocumentTemplate/ProductionOrderDemoTemplate";

const Root: React.FC = () => {
  const isAuthenticated = !!useAuthentication()[0];

  return (
    <Box height="100vh">
      <BrowserRouter>
        <Switch>
          <ProtectedRoute
            allow={!isAuthenticated}
            redirectPath=""
            path="/login"
            component={Login}
          />

          <Route path="/guest-track" component={GuestTrack} />

          <ProtectedRoute
            allow={isAuthenticated}
            redirectPath="/login"
            path="/litmon"
            component={LearningInTheMoment}
          />

          <Route>
            <MainLayout>
              <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route path="/wms" component={WMS} />
                <Route path="/print-activities" component={PrintActivities} />
                <Route
                  path="/learning-in-the-moment"
                  component={LearningInTheMomentLegacy}
                />
                <Route path="/DigiKey" component={Digikey} />
                <Route path="/Mouser" component={Mouser} />
                <Route
                  path="/generate-label"
                  component={GovPropLabelGenerator}
                />
                <ProtectedRoute
                  allow={isAuthenticated}
                  redirectPath="/login"
                  path="/datamatrix"
                  component={DataMatrixTemplate}
                />
                <Route
                  path="/production-order-demo"
                  component={ProductionOrderDemoTemplate}
                />
                <Route
                  path="/:momenttrackId/legacy"
                  component={IdLookupLegacy}
                />
                <Route path="/:momenttrackId" component={IdLookup} />
                <Redirect to="/" />
              </Switch>
            </MainLayout>
          </Route>
        </Switch>
      </BrowserRouter>
    </Box>
  );
};

export default Root;
