
import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

// import { useLocation } from 'react-router-dom';
import useNotify from '../../hooks/use-notify';
import { loginPerson, updateCurrentPerson, updateCurrentPassword, AuthenticatedPerson, AuthenticationData, getCurrentPerson } from '../../system/authentication';
import { publish, subscribe } from '../../system/pubsub';
import * as authStore from './storage';
import context from './context';

const AuthenticationProvider: React.FC = props => {
  const notify = useNotify();
  const { search } = window.location;
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [currentPerson, setCurrentPerson] = useState<AuthenticatedPerson | null>(authStore.getCurrPerson());

  const query = useMemo(()=>new URLSearchParams(search), [search]);

  const login = async (email: string, password: string) => {
    try{
      setLoading(true);
      const authData = await loginPerson(email, password);
      publish('update:authentication', authData);
      setLoading(false);
      setAuthenticated(true);

      return true;
    }
    catch(err){
      notify(err as Error);
      setLoading(false);
      return false;
    }
  };

  const logout = () => {
    setAuthenticated(false);
    setCurrentPerson(null);
    authStore.setCurrPerson(null);
    publish('update:authentication', null);
    
  };

  const updateProfile = async (updateData: any) => {
    try{
      setLoading(true);
      const updatedProfile = await updateCurrentPerson(updateData);
      setCurrentPerson(updatedProfile);
      setLoading(false);
      notify('Profile updated.');
    }
    catch(err){
      notify(err as Error);
      setLoading(false);
    }
  };

  const updatePassword = async (updateData: any) => {
    try{
      setLoading(true);
      await updateCurrentPassword(updateData);
      setLoading(false);
      notify('Password updated.');
    }
    catch(err){
      notify(err as Error);
      setLoading(false);
    }
  };

  useEffect(()=>{
    return subscribe<AuthenticationData | null>('update:authentication', ({ data, meta: { trigger } }) => {
      if(trigger === 'auth-initialization')
        return;

      if(!data){
        authStore.clearAll();
        setAuthenticated(false);
        setCurrentPerson(null);
      }
      else{
        authStore.setToken(data.access_token);
        authStore.setRefreshToken(data.refresh_token);
        authStore.setOrganization(data.org_slug);
      }
    });
  }, []);

  useLayoutEffect(()=>{
    let token: string | null, orgSlug: string | null;

    token = query.get('token'); 
    orgSlug = query.get('org_slug');

    if(!token || !orgSlug){
      token = authStore.getToken();
      orgSlug = authStore.getOrganization();
    }

    publish<AuthenticationData>('update:authentication', {
      access_token: token || '',
      refresh_token: authStore.getRefreshToken() || '',
      org_slug: orgSlug || 'default'

    }, { trigger: 'auth-initialization' });

    if(!!token && !!orgSlug)
      setAuthenticated(true);
  }, [query]);

  useLayoutEffect(()=>{
    (async ()=>{
      try{
        if(!authenticated || !!currentPerson)
          return;

        setLoading(true);
        const person = await getCurrentPerson();
        setCurrentPerson(person);
        authStore.setCurrPerson(person);
        setLoading(false);
      }
      catch(err){
        alert((err as Error).message);
        setLoading(false);
      }
    })();

  }, [authenticated, currentPerson]);

  useLayoutEffect(()=>{
    publish('update:curr_person', currentPerson);
  }, [currentPerson]);

  return (
    <context.Provider value={[!authenticated ? null : currentPerson, loading, { login, logout, updateProfile, updatePassword }]}>
      {loading && <LinearProgress />}
      {!loading && props.children}
    </context.Provider>
  );
};

export default AuthenticationProvider;
