import React, {useMemo, useState} from "react";
import {TrainingDocumentTemplateProps} from "./types";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import styles from "./styles";
import {Button} from "@material-ui/core";
import useNotify from "../../../core/hooks/use-notify";
import TrainingCertModal from "./TrainingCertModal";
import LoginRedirect from "../../../core/components/login-redirect";
import {useAuthentication} from "../../../core/providers/AuthenticationProvider";
import {RenderLinksInString} from "../../../core/system/utils/parse-template";
const useStyles = makeStyles(styles);

function getAlternateVideoUrl(url: string) {
  if (typeof url !== "string" || !url.trim() || !url.includes("/uploads/"))
    return url;

  return url.replace("/uploads/", "/uploads_compressed/");
}

const TrainingDocumentTemplate: React.FC<TrainingDocumentTemplateProps> = ({
  trainingDocument,
}) => {
  const [currentUser] = useAuthentication();
  const training = useMemo(() => {
    return trainingDocument.training_versions[
      trainingDocument.curr_version_index
    ];
  }, [trainingDocument]);

  const hasSteps =
    training.instructions && Array.isArray(training.instructions);
  const classes = useStyles();
  const [editorOpen, setEditorOpen] = useState(false);
  const [isVideoEnded, setisVideoEnded] = useState(false);
  const notify = useNotify();

  // redirect users to login if the training document is set to private
  if (
    trainingDocument.visibility === "INTERNAL" &&
    currentUser?.organization_id !== trainingDocument.organization_id
  )
    return (
      <LoginRedirect text="This learning resource is private, please login to verify identity." />
    );

  return (
    <Box pt={1} pb={10}>
      <Container>
        <video
          onEnded={() => setisVideoEnded(true)}
          controls
          className={classes.videoPlayer}
        >
          <source src={getAlternateVideoUrl(training.video_url)} />
          <source src={training.video_url} />
          Your browser does not support the video tag.
        </video>

        <Paper>
          <Box className={classes.trainingHeader} p={2}>
            <Grid
              style={{width: "max-content"}}
              container
              spacing={2}
              wrap="nowrap"
              alignItems="center"
            >
              <Grid item xs="auto">
                <Avatar>
                  <Icon fontSize="inherit">school</Icon>
                </Avatar>
              </Grid>
              <Grid item xs>
                <Typography variant="h5">{training.title}</Typography>
                <Chip
                  label={trainingDocument.visibility}
                  icon={
                    <Icon fontSize="small">
                      {trainingDocument.visibility === "PUBLIC"
                        ? "public"
                        : "badge"}
                    </Icon>
                  }
                  size="small"
                />
              </Grid>
            </Grid>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                try {
                  if (isVideoEnded) {
                    setEditorOpen(true);
                  } else {
                    throw new Error(
                      "Please complete the video before you download certificate"
                    );
                  }
                } catch (error) {
                  notify(error as Error);
                }
              }}
            >
              Download Certificate
            </Button>
          </Box>

          <Divider light />

          <Box p={2}>
            <Typography variant="h6" gutterBottom>
              Steps to remember
            </Typography>
          </Box>

          <Box p={2} pt={0}>
            {!hasSteps && (
              <Typography variant="body1">No instructions.</Typography>
            )}

            {hasSteps &&
              training.instructions.map((step, index) => (
                <Box key={`step-${index + 1}`} pb={1} pt={0}>
                  <Grid container wrap="nowrap" spacing={1}>
                    <Grid item xs="auto">
                      <code>{index + 1}.</code>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="body1" gutterBottom>
                        <RenderLinksInString inputString={step} />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ))}
          </Box>
        </Paper>

        <TrainingCertModal
          hr_email={training?.email || ""}
          editorOpen={editorOpen}
          setEditorOpen={setEditorOpen}
          title={training.title}
        />
      </Container>
    </Box>
  );
};

export default TrainingDocumentTemplate;
